//LP form用ライブラリです。

//機能
//・バリデーション
//  - エラーメッセージや必須項目はHTML側で設定
//  - フォーカスが外れたときバリデーションチェック
//  - エラーがない場合にsubmitボタン活性化 ←今回に限りこの機能は無し！！
//・確認画面へのデータ流し込み
//・エラー表示は別画面 ←今回のみの仕様
//・APIを叩いてメール送信

const lpForm = (() => {
  return class LpForm {

    constructor(rootElement, options) {
  
      this.form = this.convertElement(rootElement);
      if (!this.form) return;
  
      const defaultOptions = {
        storageName: "form-session-storage", //セッションストレージ名
        confirmURL: "/contact/confirm/", //確認画面のURL
        errorURL: "/contact/error/", //エラー画面のURL
        finishURL: "/contact/finish/", //完了画面画面のURL
        sendAPI: "/api/contacts/send", //メール送信用のAPI_URL
        apiKey: "GrVCBC8RDj6Y4rLaMf-zBE5GR3m3_mde", //フォームのAPIキー
      }
  
      this.options = this.mergeOptions(defaultOptions, options)
  
      this.inputElements = this.form.querySelectorAll("input,select,textarea");
      this.submit = this.form.querySelector('[type="submit"]');

  
      this.init();
      
    }
    
    init() {
      this.setUpFormstorage();
      this.validateSubmit();
      this.setUpAttribute();
      this.handleEvent();
    }
  
    mergeOptions(defaultOptions, options) {
      const mergeOptions = Object.assign(defaultOptions, options || {});
      return mergeOptions
    }
  
    setUpFormstorage() {
      //セッションストレージに対象のフォームのデータがある場合はセットする
      this.applyState(sessionStorage.getItem(this.options.storageName))
    }
  
    //必要な属性をセットする
    setUpAttribute() {
      this.inputElements.forEach((currentInput) => {
        //pattern（正規表現）に指定があればセット
        if(currentInput.hasAttribute("data-validation-pattern")) {
          const pattern = currentInput.getAttribute("data-validation-pattern")
          currentInput.setAttribute("pattern", this.getValidateType(pattern))
        }
      })
    }
  
    //バリデーション用正規表現
    getValidateType(v) {
      let type;
      switch (v) {
        //全角カナ
        case "zenkana":
          type = "[ァ-ンヴー|　| ]+$";
          break;
        //電話番号
        case "tel":
          type = "\\d{2,4}-?\\d{2,4}-?\\d{3,4}";
          break;
        case "tel-not-hyphen":
          type = "^[0-9]{8,11}$";
          break;
      }
      return type;
    }
  
    //イベントを登録する。
    handleEvent() {
      this.handleValidation(this.inputElements);
      this.handleSubmit(this.submit);
    }
  
    //バリデーションに関するイベントを登録する。
    handleValidation(input) {
      input.forEach((currentInput) => {
        // 入力内容が変更されたらメッセージを表示
        currentInput.addEventListener("change", this.displayValidation.bind(this));
        currentInput.addEventListener("blur", this.displayValidation.bind(this));
  
        // 送信ボタンのバリデーション操作
        currentInput.addEventListener("change", this.validateSubmit.bind(this));
      });
    }
  
    //送信ボタンに関するイベントを登録する。
    handleSubmit(submit) {
      submit.addEventListener("click", this.pressSubmit.bind(this));
    };
  
    //バリデーションメッセージを表示します。
    displayValidation(event) {
      const targetInput = event.target;
      const targetName = targetInput.getAttribute("name");
      const invalidMessage = targetInput.getAttribute("data-invalid-text");
      const messageArea = this.form.querySelector(
        `[data-validation="${targetName}"]`
      );
      const hasValidateMessage =
        messageArea !== null && targetInput.hasAttribute("data-invalid-text");
      const isValid = targetInput.validity.valid;
  
      targetInput.setAttribute("data-is-valid", isValid);
  
      if (hasValidateMessage) {
        messageArea.innerHTML = isValid ? "" : invalidMessage;
      }

      // 第2バリデーションメッセージ ===================
      // 今回のみの仕様
      const invalidMessage2 = targetInput.getAttribute("data-invalid-text2");
      const messageArea2 = this.form.querySelector(
        `[data-validation2="${targetName}"] label`
      );
      const hasValidateMessage2 =
        messageArea2 !== null && targetInput.hasAttribute("data-invalid-text2");
      const isValid2 = targetInput.validity.valid;
  
      targetInput.setAttribute("data-is-valid2", isValid2);
  
      if (hasValidateMessage2) {
        messageArea2.innerHTML = isValid2 ? "" : invalidMessage2;
        messageArea2.style.display = isValid2 ? "none" : "block"
      }
      // ================================
  
      return;
    }
  
  
    //フォームの内容に応じて送信ボタンの状態を変えます。
    validateSubmit() {
      const isValid = this.form.checkValidity();
      const submitButton = this.submit;
      const messageArea = this.form.querySelector('[data-validation="submit"]');
      const invalidMessage = this.submit.getAttribute("data-invalid-text");
    
      submitButton.setAttribute("aria-disabled", !isValid);
      // messageArea.innerHTML = isValid ? "" : invalidMessage;
    
      return;
    }
  
    //送信ボタン押下時の挙動
    pressSubmit(event) {
      event.preventDefault();

      //フォームの入力内容のチェック 問題なければtrue 誤りがあればfalse
      const isValid = this.form.checkValidity();
      //フォームの入力データを格納
      const formData = this.getFormDataObject();
      //フォームの入力データのjsonを格納
      const formData_json = JSON.stringify(formData);
  
      //入力内容に誤りがあった場合
      if (!isValid) {
        event.preventDefault();
  
        const requiredTargets = this.form.querySelectorAll('[required][aria-required="true"]')
        let errorMessages = [];
        requiredTargets.forEach((requiredTarget) => {
          if(!requiredTarget.checkValidity()) {
            errorMessages.push(requiredTarget.getAttribute('data-invalid-text'))
          }
        })

        //入力内容をセッションストレージにセット
        sessionStorage.setItem(this.options.storageName, formData_json);

        //エラー内容をセッションストレージにセット
        const errorMessages_json = JSON.stringify(errorMessages);
        sessionStorage.setItem(`${this.options.storageName}-error`,errorMessages_json);
  
        window.top.location.href = this.options.errorURL; //エラーページへ遷移
  
        return
      }
  
      //確認画面かのチェック
      const isConfirm = this.submit.classList.contains('-confirm')
  
      if(isConfirm) {
        sessionStorage.setItem(this.options.storageName, formData_json);
        // this.formstorage.save();
        window.top.location.href = this.options.confirmURL;
        event.preventDefault();
        return
      }
      
      //以下、送信処理 ==================================

      //APIに渡すフォームデータをを作成
      const sendData = new FormData(this.form);
      //API Keyをフォームデータに追加
      sendData.append('key', this.options.apiKey);

      //APIを叩く処理の関数
      const fetchSendFunc = (url, sendData) =>
        fetch(url, {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: sendData,
        });

      const sendMail = async () => {
        const response = await fetchSendFunc(this.options.sendAPI, sendData);
        return response;
      }

      //送信処理実行
      sendMail()
        .then(response => {

          //ネットワークエラー以外でもcatchでエラー処理
          if (!response.ok) {
            console.error('response.ok:', response.ok);
            console.error('response.status:', response.status);
            console.error('response.statusText:', response.statusText);
            throw new Error(response.statusText);
          }

          //以下、成功時の場合
          if(response.status === 200) {
            console.log('success')
            console.log(response.status);
            // sessionStorage.removeItem(this.options.storageName);
            var request = JSON.parse(sessionStorage.getItem(this.options.storageName));
            if(request) {
              var requestValue = request.email;
              sessionStorage.setItem('setEmail',requestValue);
            }
            window.top.location.href = this.options.finishURL;
          }
        })
        .catch(err => {
            // fetchでcatchに入る場合はネットワークエラーだが、400系や500系のエラーでもここに入るように上で記述している
            console.log(err);
            alert(
              '送信に失敗しました。インターネットに正しく接続されていることを確認して再度送信ください。'
            )
        });

      // event.preventDefault();
      // return;
    };

    //フォームの入力内容を取得しオブジェクトを返す（確認画面用のデータ） ※APIに渡すデータではない
    getFormDataObject() {
      const formData = new FormData(this.form);
      const object = {};

      for (let entry of formData.entries()) {
        let key = entry[0];
        let value = entry[1];
        if(value) {
          object[key] = value;
        }
      }
      return object;
    }
    
    convertElement(obj) {
      if (obj instanceof HTMLElement) {
        return obj
      }
      // if (obj instanceof jQuery) {
      //   return obj[0]
      // }
      return document.querySelector(obj);
    }
  
    //セッションストレージの内容をフォームにセット
    applyState(str) {
      const obj = JSON.parse(str);
      // const obj = queryString.parse(str.replace(/^"(.*)"$/, "$1"));
      for (const key in obj) {
        let flag = false;
        // console.log(key)
        const target = this.form.querySelector(`[name="${key}"]`);
        const targets = this.form.querySelectorAll(`[name="${key}"]`);
  
        if (!target) {
          continue;
        }
  
  
        if (flag) {
          continue;
        }
  
        if (targets && targets.length > 1) {
          const arr = obj[key];
          [].forEach.call(targets, (tar, index) => {
            if (tar.type === 'checkbox') {
              if (arr.forEach) {
                arr.forEach(item => {
                  if (item === tar.value) {
                    tar.checked = true;
                  }
                });
              } else {
                if (arr === tar.value) {
                  tar.checked = true;
                }
              }
            } else if (tar.type === 'radio') {
              if (tar.value === arr) {
                tar.checked = true;
              }
            }
          });
          continue;
        }
  
        if (target.type === 'radio' || target.type === 'checkbox') {
          if (obj[key] === target.value) {
            target.checked = true;
          }
        } else {
          target.value = obj[key];
        }
      }
    }
  
  }
  
})();

export default lpForm;


//フォームバリデーションここまで=============================

