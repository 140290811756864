/**
 * @see https://support.google.com/google-ads/answer/7012522?hl=ja
 */


function getParam(p) {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getExpiryRecord(value) {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 日の有効期限、単位はミリ秒

  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value: value,
    expiryDate: expiryDate
  };
}

function addGclid() {
  const gclidParam = getParam('gclid');
  const gcridFildName = "gclid_field";// ここに GCLID のフォーム項目の name を指定
  const gclidFormFields = document.querySelectorAll(`[type="hidden"][name="${gcridFildName}"]`);
  let gclidRecord = null;

  const gclsrcParam = getParam('gclsrc');
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam);
    localStorage.setItem('gclid', JSON.stringify(gclidRecord));
  }

  const gclid = gclidRecord || JSON.parse(localStorage.getItem('gclid'));
  const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

  if (gclidFormFields && isGclidValid) {
    gclidFormFields.forEach(function (field) {
      field.value = gclid.value;
    });
  }
}

window.addEventListener('load', addGclid);