import LpForm from '../../libs/lpForm.js';

//お引越し手続きフォーム
new LpForm('#moushikomi-form', {
  storageName: "moushikomi-form", //セッションストレージ名
  confirmURL: "/form/moushikomi-confirm.html", //確認画面のURL
  errorURL: "/form/moushikomi-error.html", //エラー画面のURL
  finishURL: "/form/thanks_moushikomi.html", //完了画面画面のURL
  sendAPI: "/api/moushikomi/send", //メール送信用のAPI_URL
})

//お引越し手続きフォーム2（トップ）
new LpForm('#moushikomi-form-2', {
  storageName: "moushikomi-form-2", //セッションストレージ名
  confirmURL: "/form/moushikomi-2-confirm.html", //確認画面のURL
  errorURL: "/form/moushikomi-2-error.html", //エラー画面のURL
  finishURL: "/form/thanks_moushikomi-2.html", //完了画面画面のURL
  sendAPI: "/api/moushikomi2/send", //メール送信用のAPI_URL
})

//お見積りフォーム
new LpForm('#mitsumori-form', {
  storageName: "mitsumori-form", //セッションストレージ名
  confirmURL: "/form/mitsumori-confirm.html", //確認画面のURL
  errorURL: "/form/mitsumori-error.html", //エラー画面のURL
  finishURL: "/form/thanks_mitsumori.html", //完了画面画面のURL
  sendAPI: "/api/mitsumori/send", //メール送信用のAPI_URL
})

//エリアチェックフォーム
new LpForm('#areacheck-form', {
  storageName: "areacheck-form", //セッションストレージ名
  confirmURL: "/form/areacheck-confirm.html", //確認画面のURL
  errorURL: "/form/areacheck-error.html", //エラー画面のURL
  finishURL: "/form/thanks_areacheck.html", //完了画面画面のURL
  sendAPI: "/api/areacheck/send", //メール送信用のAPI_URL
})

//エリアチェックフォーム2
new LpForm('#areacheck-form-2', {
  storageName: "areacheck-form-2", //セッションストレージ名
  confirmURL: "/form/areacheck-2-confirm.html", //確認画面のURL
  errorURL: "/form/areacheck-2-error.html", //エラー画面のURL
  finishURL: "/form/thanks_areacheck-2.html", //完了画面画面のURL
  sendAPI: "/api/areacheck2/send", //メール送信用のAPI_URL
})

//申し込みフォーム(SP)
new LpForm('#moushikomi-form-sp', {
  storageName: "moushikomi-form-sp", //セッションストレージ名
  confirmURL: "/form_smp/moushikomi-confirm.html", //確認画面のURL
  errorURL: "/form_smp/moushikomi-error.html", //エラー画面のURL
  finishURL: "/form_smp/thanks_moushikomi.html", //完了画面画面のURL
  sendAPI: "/api/moushikomi-sp/send", //メール送信用のAPI_URL
})

//申し込みフォーム2(SP)
new LpForm('#moushikomi-form-2-sp', {
  storageName: "moushikomi-form-2-sp", //セッションストレージ名
  confirmURL: "/form_smp/moushikomi-2-confirm.html", //確認画面のURL
  errorURL: "/form_smp/moushikomi-2-error.html", //エラー画面のURL
  finishURL: "/form_smp/thanks_moushikomi-2.html", //完了画面画面のURL
  sendAPI: "/api/moushikomi2-sp/send", //メール送信用のAPI_URL
})

//エリアチェック(SP)
new LpForm('#areacheck-form-sp', {
  storageName: "areacheck-form-sp", //セッションストレージ名
  confirmURL: "/form_smp/areacheck-confirm.html", //確認画面のURL
  errorURL: "/form_smp/areacheck-error.html", //エラー画面のURL
  finishURL: "/form_smp/thanks_areacheck.html", //完了画面画面のURL
  sendAPI: "/api/areacheck-sp/send", //メール送信用のAPI_URL
})

//エリアチェック2(SP)
new LpForm('#areacheck-form-2-sp', {
  storageName: "areacheck-form-2-sp", //セッションストレージ名
  confirmURL: "/form_smp/areacheck-2-confirm.html", //確認画面のURL
  errorURL: "/form_smp/areacheck-2-error.html", //エラー画面のURL
  finishURL: "/form_smp/thanks_areacheck-2.html", //完了画面画面のURL
  sendAPI: "/api/areacheck2-sp/send", //メール送信用のAPI_URL
})

//お見積りフォーム(SP)
new LpForm('#mitsumori-form-sp', {
  storageName: "mitsumori-form-sp", //セッションストレージ名
  confirmURL: "/form_smp/mitsumori-confirm.html", //確認画面のURL
  errorURL: "/form_smp/mitsumori-error.html", //エラー画面のURL
  finishURL: "/form_smp/thanks_mitsumori.html", //完了画面画面のURL
  sendAPI: "/api/mitsumori-sp/send", //メール送信用のAPI_URL
})

